<template>
  <div>
    <div class="inquiry-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-5 p-0">
            <div class="form-wrapper left-panel position-relative">
              <div class="logo">
                <router-link
                  :to="{ name: 'anonymousRoute-home' }"
                  class="logo-image"
                >
                  <img
                    src="/img/digital-staging-logo.svg"
                    alt="Digital Staging"
                  />
                </router-link>
              </div>
              <div
                class="register-banner-mobile d-xs-block d-sm-block d-md-block d-lg-none"
              >
                <img src="/img/login-banner-mobile.png" alt="Sign up Banner" />
              </div>
              <div class="inquiry-wrapper">
                <div class="register position-relative">
                  <h2 class="blue-text font-weight-bold">お問い合わせ</h2>
                  <p class="text-end text-light-gray mb-0 col-12 text-right">
                    * 入力必須の項目
                  </p>
                  <form v-on:submit="validateInquiry">
                    <div class="row">
                      <div class="col-12">
                        <div class="mt-4">
                          <p class="m-0 font-weight-bold">貴社名</p>
                          <input
                            id="company"
                            class="form-control shadow-1"
                            type="text"
                            minlength="2"
                            maxlength="100"
                            v-model="company"
                            v-on:keyup="formMessage = null"
                            placeholder="貴社名"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="mt-4">
                          <p class="m-0 font-weight-bold">お名前*</p>
                          <input
                            id="name"
                            class="form-control shadow-1"
                            type="text"
                            minlength="2"
                            maxlength="100"
                            v-model="name"
                            v-on:keyup="formMessage = null"
                            placeholder="お名前"
                            v-bind:class="{ 'alert-danger': formErrors.name }"
                          />
                          <p
                            v-if="formErrors.name"
                            class="text-danger font-weight-bold"
                          >
                            お名前が入力されていません
                          </p>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="mt-4">
                          <p class="m-0 font-weight-bold">メールアドレス*</p>
                          <input
                            id="email"
                            class="form-control shadow-1"
                            type="email"
                            minlength="2"
                            maxlength="100"
                            v-model="email"
                            v-on:keyup="formMessage = null"
                            placeholder="メールアドレス"
                            v-bind:class="{ 'alert-danger': formErrors.email }"
                          />
                          <p
                            v-if="formErrors.email"
                            class="text-danger font-weight-bold"
                          >
                            メールアドレスが入力されていません
                          </p>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="mt-4">
                          <p class="m-0 font-weight-bold">お問い合わせ種別*</p>
                          <b-form-select
                            class="shadow-1"
                            v-model="inquiries"
                            :options="getInquiryTypes()"
                            v-bind:class="{
                              'alert-danger': formErrors.inquiries,
                            }"
                          ></b-form-select>
                          <p
                            v-if="formErrors.inquiries"
                            class="text-danger font-weight-bold"
                          >
                            お問い合わせ種別が入力されていません
                          </p>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="mt-4">
                          <p class="m-0 font-weight-bold">お問い合わせ*</p>
                          <b-form-textarea
                            v-model="message"
                            placeholder="お問い合わせ内容を入力して下さい"
                            rows="6"
                            max-rows="6"
                            no-resize
                            v-bind:class="{
                              'alert-danger': formErrors.message,
                            }"
                          ></b-form-textarea>
                          <p
                            v-if="formErrors.message"
                            class="text-danger font-weight-bold"
                          >
                            メッセージが入力されていません
                          </p>
                        </div>
                      </div>
                      <div class="col-12">
                        <button
                          class="btn btn-ds mt-5 dark-blue white-text"
                          type="submit"
                          v-waves.light
                        >
                          送信
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-7 p-0 d-none d-lg-block banner-wrap">
            <img src="/img/login-banner.png" alt="Sign up Banner" />
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="inquiry-success-modal"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <h5
        class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
        v-on:click="$bvModal.hide('inquiry-success-modal')"
      >
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </h5>
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
          お問い合わせに成功しました！
        </h5>
        <div class="row mb-3 mt-5">
          <div class="col-12">
            <button
              class="btn btn-ds dark-blue white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="exitConfirmation"
            >
              オーケー
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <main-footer></main-footer>
  </div>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';

// Import Services
import { globalService_CreateInquiry } from '../services/global';

// Import Custom Components
import mainFooter from '../components/customer/Main-Footer.vue';

//Import Common
import { getInquiryTypes } from '../common/Utility';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Inquiry',
    };
  },

  components: {
    'main-footer': mainFooter,
  },

  data() {
    return {
      company: null,
      name: null,
      email: null,
      inquiries: null,
      message: null,
      formErrors: {
        name: false,
        email: false,
        inquiries: false,
        message: false,
      },
      formMessage: null,
    };
  },
  watch: {
    name(newVal) {
      this.formErrors.name = newVal ? false : true;
    },
    email(newVal) {
      this.formErrors.email = newVal ? false : true;
    },
    inquiries(newVal) {
      this.formErrors.inquiries = newVal ? false : true;
    },
    message(newVal) {
      this.formErrors.message = newVal ? false : true;
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'currentRoute']),
  },

  mounted() {
    this.setUserInfo();
  },

  methods: {
    getInquiryTypes,
    exitConfirmation() {
      this.$bvModal.hide('inquiry-success-modal');
      this.$router.go(-1);
    },
    setUserInfo() {
      this.name = this.currentUser.user_profile
        ? this.currentUser.user_profile.first_name +
          ' ' +
          this.currentUser.user_profile.last_name
        : null;
      this.email = this.currentUser
        ? this.currentUser.email
          ? this.currentUser.email
          : null
        : null;
    },
    validateInquiry(e) {
      e.preventDefault();
      this.formErrors = {
        name: !this.name ? true : false,
        email: !this.email ? true : false,
        inquiries: !this.inquiries ? true : false,
        message: !this.message ? true : false,
      };

      if (!Object.values(this.formErrors).includes(true)) {
        this.submitInquire();
      } else {
        console.log('errors', this.formErrors);
      }
    },
    submitInquire() {
      let _this = this;
      _this.formMessage = null;

      _this.$store.state.modalLoaderMessage = '送信中';
      _this.$bvModal.show('modal-loader');

      globalService_CreateInquiry({
        user_id: _this.currentUser.id ? _this.currentUser.id : '',
        company_name: _this.company,
        name: _this.name,
        email_address: _this.email,
        inquiries: _this.inquiries,
        message: _this.message,
      })
        .then((response) => {
          console.log(response);

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
            _this.$bvModal.show('inquiry-success-modal');
          }, 500);
        })
        .catch((error) => {
          console.log(error);

          _this.formMessage =
            'プロフィールの更新中にエラーが起こりました。再度お試しください。';

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        });
    },
  },
};
</script>

<style scoped>
.inquiry-wrapper .form-wrapper {
  padding: 42px;
  height: 100%;
}

@media (max-width: 991.98px) {
  .inquiry-wrapper .form-wrapper {
    height: auto;
  }
}

.inquiry-wrapper .form-wrapper .lets-start {
  background-color: #00adee;
  color: #fff;
  border-radius: 25px;
  position: absolute;
  right: -178px;
  padding: 15px 12px;
  width: 175px;
  text-align: center;
  height: 53px;
  margin: 5px 0;
}

.inquiry-wrapper .form-wrapper .inquiry-wrapper {
  padding: 40px 40px 10px 140px;
}

.inquiry-wrapper .form-wrapper .inquiry-wrapper .title-form {
  font-weight: 500;
}

.inquiry-wrapper .form-wrapper .inquiry-wrapper form .form-group {
  position: relative;
}

.inquiry-wrapper .form-wrapper .inquiry-wrapper form .btn-google {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bfbebe;
  border-radius: 100px;
  color: #707070;
}

.inquiry-wrapper .form-wrapper .inquiry-wrapper form .option-links p a,
.inquiry-wrapper .form-wrapper .inquiry-wrapper form .option-links a {
  color: #034ea1;
  text-decoration: underline !important;
}

.inquiry-wrapper .form-wrapper .form-footer {
  padding: 10px 40px 10px 140px;
  position: absolute;
  margin: 1em auto;
  overflow: hidden;
  bottom: 0;
  width: 80%;
  margin: auto;
  left: 0;
  bottom: 0;
  right: 0;
}

.inquiry-wrapper .form-wrapper .form-footer ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -1px;
  list-style: none;
  padding-left: 0;
}

.inquiry-wrapper .form-wrapper .form-footer ul li {
  flex-grow: 1;
  flex-basis: auto;
  margin: 0.25em 0;
  padding: 0 1em;
  text-align: center;
  border-left: 1px solid #ccc;
}

.inquiry-wrapper .form-wrapper .form-footer ul li:first-child {
  border-left: 0 !important;
}

.inquiry-wrapper .form-wrapper .form-footer ul li a {
  text-decoration: underline !important;
  color: #9b9b9b;
}

.inquiry-wrapper .banner-wrap {
  overflow: hidden;
  padding: 0;
}

.inquiry-wrapper .banner-wrap img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: left center;
  object-position: left center;
}

@media (max-width: 991.98px) {
  .inquiry-wrapper .form-wrapper {
    padding: 0 !important;
  }

  .inquiry-wrapper .form-wrapper .logo {
    padding: 20px;
  }

  .inquiry-wrapper .form-wrapper .logo .logo-image img {
    width: 172px;
  }

  .inquiry-wrapper .form-wrapper .logo .lets-start {
    right: 20px !important;
    padding: 8px 10px;
    height: auto;
    margin: 0;
  }

  .inquiry-wrapper .form-wrapper .inquiry-wrapper {
    padding: 20px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    position: relative;
    top: -35px;
    background: #fff;
  }

  .inquiry-wrapper .form-wrapper .form-footer {
    padding: 20px;
    position: relative !important;
    width: 100%;
  }

  .inquiry-wrapper .form-wrapper .register-banner-mobile img {
    width: 100%;
    height: 251px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .inquiry-wrapper .form-wrapper {
    padding: 10px 10px 10px 42px !important;
  }
  .inquiry-wrapper .form-wrapper .inquiry-wrapper,
  .inquiry-wrapper .form-wrapper .form-footer {
    padding: 40px 10px 10px 0 !important;
  }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
  .inquiry-wrapper .form-wrapper {
    padding: 10px 10px 10px 42px !important;
  }
  .inquiry-wrapper .form-wrapper .inquiry-wrapper,
  .inquiry-wrapper .form-wrapper .form-footer {
    padding: 40px 10px 10px 40px !important;
  }
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 0.5px solid #b8b8b8;
}

.separator:not(:empty)::before {
  margin-right: 8px;
}

.separator:not(:empty)::after {
  margin-left: 8px;
}
</style>
